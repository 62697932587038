<template>
  <div class="main-bg-color h-100vh position-relative">
    <div class="logo-image-container text-center complain-header mx-3">
      <!-- <img src="@/assets/images/kerry-logo.jpg" alt="Kitten" class="logo-img" /> -->
      <img src="@/assets/images/KEX_logo_RGB-White.png" alt="Kitten" class="logo-img" />
    </div>
    <!-- <div class="position-btn-language">
      <b-button class="btn-language ml-2" @click="changeLanguage">
        TH / EN
      </b-button>
    </div> -->

    <div class="d-flex justify-content-center w-100 complain-container">
      <div class="w-100 my-2">
        <div class="d-flex justify-content-center">
          <div class="card p-3 mx-3 complain-card complain-position pt-4 mb-5">
            <template v-if="form.TopicId !== 7">
              <b-row>
                <b-col>
                  <p class="mb-0 text-label">
                    {{ $t("personalInfo2") }}
                    <span class="text-error mx-1">*</span>
                  </p>
                  <p class="mb-2">
                    <span class="detail-format">
                      {{ $t("personalInfoValidate") }}</span
                    >
                  </p>
                </b-col>
              </b-row>
              <b-row class="">
                <b-col md="6">
                  <InputText
                    :textFloat="$t('fullname2')"
                    :placeholder="$t('fullname2')"
                    type="text"
                    name="name"
                    isRequired
                    v-model="Fullname"
                    :isValidate="$v.Fullname.$error"
                    :v="$v.Fullname"
                  />
                </b-col>
              </b-row>
              <b-row class="">
                <b-col md="6">
                  <InputText
                    :textFloat="$t('telephone2')"
                    :placeholder="$t('telephone2')"
                    type="tel"
                    name="telephone"
                    @onKeypress="isNumber($event)"
                    isRequired
                    v-model="form.Phone"
                    :isValidate="$v.form.Phone.$error"
                    :v="$v.form.Phone"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    :textFloat="$t('email2')"
                    :placeholder="$t('email2')"
                    type="text"
                    name="Email"
                    v-model="form.Email"
                    :isValidate="$v.form.Email.$error"
                    :v="$v.form.Email"
                  />
                </b-col>
              </b-row>

              <!-- <b-row class="">
              <b-col class="d-flex">
                <b-form-group
                  :label="$t('contactChanne2')"
                  v-slot="{ ariaDescribedby }"
                  class="mb-1"
                >
                  <b-form-radio-group
                    id="radio-group-chanel"
                    v-model="form.ContactChannel"
                    :aria-describedby="ariaDescribedby"
                    name="radio-sub-component-channel"
                  >
                    <b-form-radio value="Telephone">{{
                      $t("telephone")
                    }}</b-form-radio>
                    <b-form-radio value="Email">{{ $t("email") }}</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row> -->
              <!-- <template> -->
              <b-row class="">
                <b-col md="6">
                  <InputText
                    :textFloat="$t('trackingNo2')"
                    :placeholder="$t('pleaseEnterTrackingNo2')"
                    type="text"
                    name="trackingNo"
                    v-model="form.TrackingNo"
                  />
                </b-col>
                <!-- <b-col md="6" v-if="form.TopicId == 1">
                  <InputText
                    :textFloat="$t('parcelAmount')"
                    :placeholder="$t('pleaseEnterParcelAmount')"
                    type="number"
                    name="amount"
                    isRequired
                    @onKeypress="isNumber($event)"
                    v-model="form.Amount"
                    :isValidate="$v.form.Amount.$error"
                    :v="$v.form.Amount"
                  />
                </b-col> -->
              </b-row>

              <b-row class="mt-2">
                <b-col md="6" order-md="12"> </b-col>
                <b-col class="custom-mt-2" md="6">
                  <InputSelect
                    class="mb-2"
                    :title="$t('pleaseSelectTopic2')"
                    name="TopicId"
                    v-bind:options="complainOptions"
                    v-on:onDataChange="onTopicChange"
                    v-model="form.TopicId"
                    valueField="id"
                    textField="name"
                    isRequired
                    :v="$v.form.TopicId"
                  />
                </b-col>
              </b-row>

              <!-- <b-row class="" v-if="form.TopicId !== 1 && form.TopicId !== 5"> -->
              <b-row class="">
                <b-col md="6">
                  <InputText
                    :textFloat="$t('description3')"
                    :placeholder="$t('description4')"
                    type="text"
                    name="trackingNo"
                    v-model="form.Description"
                    isRequired
                    :isValidate="$v.form.Description.$error"
                    :v="$v.form.Description"
                  />
                </b-col>
              </b-row>

              <!-- <b-row v-if="form.TopicId !== 5"> -->
              <b-row>
                <b-col>
                  <UploadFilePanel
                    :dataList="form.Attachment"
                    :dataBase64List="fileBase64List"
                    :textFloat="$t('attachedFile2')"
                    :placeholder="$t('pleaseSelectFile2')"
                    :fileName="fileName"
                    v-model="form.Attachment"
                    format="all"
                    name="fileUpload"
                    :text="$t('fileValidate2')"
                    :maxFileLength="5"
                    v-on:onFileChange="onImageChange"
                    isMultiple
                    typeFile="image/jpeg, image/png, application/pdf"
                    size="lg"
                    v-bind:isValidate="$v.form.Attachment.$error"
                    :v="$v.form.Attachment"
                  />
                </b-col>
              </b-row>
              <b-row v-if="form.TopicId !== 5">
                <b-col class="d-flex image-list-panel overflow-auto">
                  <!-- <CoolLightBox
                    :items="form.Attachment"
                    :index="index"
                    @close="index = null"
                  >
                  </CoolLightBox>

                  <div class="images-wrapper d-flex">
                    <div
                      class="image"
                      v-for="(item, imageIndex) in form.Attachment"
                      :key="imageIndex"
                      @click="beforeOpenLightBox(imageIndex)"
                    >
                      <div
                        v-if="checkFileExtension(item) == 'pdf'"
                        class="d-flex"
                      >
                        <font-awesome-icon
                          class="icon-pdf"
                          icon="file-pdf"
                          color="#F37021"
                        />

                        <font-awesome-icon
                          class="icon-pdf-delete"
                          icon="times-circle"
                          color="#E0E0E0"
                          @click="deleteImage(imageIndex)"
                        />
                      </div>
                      <div
                        v-else-if="
                          checkFileExtension(item) == 'mp4' ||
                          checkFileExtension(item) == 'MOV'
                        "
                        class="
                          panel-bg-file-image
                          position-relative
                          p-0
                          embed-responsive
                          banner-video
                          embed-responsive-16by9
                        "
                      >
                        <font-awesome-icon
                          class="icon-play"
                          icon="play-circle"
                          color="#FFFFFF"
                        />
                        <video ref="videoRef" class="w-100 video-box">
                          <source :src="item" type="video/mp4" />
                        </video>
                        <font-awesome-icon
                          class="icon-pdf-delete"
                          icon="times-circle"
                          color="#E0E0E0"
                          @click="deleteImage(imageIndex)"
                        />
                      </div>
                      <div
                        v-else
                        class="panel-bg-file-image mr-1 mb-1"
                        v-bind:style="{
                          backgroundImage: 'url(' + item + ')'
                        }"
                      >
                        <font-awesome-icon
                          class="icon-delete"
                          icon="times-circle"
                          color="#E0E0E0"
                          @click="deleteImage(imageIndex)"
                        />
                      </div>
                    </div>
                  </div> -->
                  <div v-for="(item, index) in form.Attachment" :key="index">
                    <div
                      v-if="checkFileExtension(item) == 'pdf'"
                      class="d-flex"
                    >
                      <font-awesome-icon
                        class="icon-pdf"
                        icon="file-pdf"
                        color="#F37021"
                      />

                      <font-awesome-icon
                        class="icon-pdf-delete"
                        icon="times-circle"
                        color="#E0E0E0"
                        @click="deleteImage(index)"
                      />
                    </div>
                    <div
                      v-else-if="checkFileExtension(item) == 'mp4'"
                      class="panel-bg-file-image position-relative p-0 embed-responsive banner-video embed-responsive-16by9"
                    >
                      <font-awesome-icon
                        class="icon-play"
                        icon="play-circle"
                        color="#FFFFFF"
                      />
                      <video ref="videoRef" class="w-100 video-box">
                        <source :src="item" type="video/mp4" />
                      </video>
                      <font-awesome-icon
                        class="icon-pdf-delete"
                        icon="times-circle"
                        color="#E0E0E0"
                        @click="deleteImage(index)"
                      />
                    </div>
                    <div
                      v-else
                      class="panel-bg-file-image mr-1 mb-1"
                      v-bind:style="{
                        backgroundImage: 'url(' + item + ')'
                      }"
                    >
                      <font-awesome-icon
                        class="icon-delete"
                        icon="times-circle"
                        color="#E0E0E0"
                        @click="deleteImage(index)"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>

              <!-- <b-row class="" v-if="form.TopicId == 5">
                <b-col md="6" class="d-flex">
                  <b-form-group
                    :label="$t('informationChange')"
                    v-slot="{ ariaDescribedby }"
                    class=""
                  >
                    <b-form-radio-group
                      id="radio-group-target"
                      v-model="form.Target"
                      :aria-describedby="ariaDescribedby"
                      name="radio-sub-component"
                    >
                      <b-form-radio value="Telephone">{{
                        $t("telephone")
                      }}</b-form-radio>
                      <b-form-radio value="Address">{{
                        $t("address")
                      }}</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>
              </b-row> -->

              <!-- <b-row
                class=""
                v-if="form.TopicId == 5 && form.Target == 'Telephone'"
              >
                <b-col md="6">
                  <InputText
                    :textFloat="$t('telephone')"
                    :placeholder="$t('telephone')"
                    :isRequired="[5].includes(form.TopicId)"
                    type="tel"
                    @onKeypress="isNumber($event)"
                    name="sortorder"
                    v-model="form.Description"
                    :isValidate="$v.form.Description.$error"
                    :v="$v.form.Description"
                  />
                </b-col>
              </b-row> -->
              <!-- <b-row
                class=""
                v-if="form.TopicId == 5 && form.Target == 'Address'"
              >
                <b-col md="6">
                  <InputText
                    :textFloat="$t('address')"
                    :placeholder="$t('address')"
                    :isRequired="[5].includes(form.TopicId)"
                    type="text"
                    name="sortorder"
                    v-model="form.Description"
                    :isValidate="$v.form.Description.$error"
                    :v="$v.form.Description"
                  />
                </b-col>
              </b-row> -->
              <b-row class="mt-2">
                <b-col class="">
                  <b-form-checkbox
                    id="checkbox-1"
                    name="checkbox-1"
                    v-model="form.Consent"
                  >
                    <span>{{ $t("consentMessage4") }}</span
                    ><a :href="urlLink" target="_blank">{{
                      $t("consentMessage5")
                    }}</a
                    ><span>{{ $t("consentMessage6") }}</span>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </template>
            <template v-if="form.TopicId == 7">
              <b-row>
                <b-col cols="12">
                  <InputTextArea
                    class=""
                    rows="8"
                    :textFloat="$t('survey2')"
                    :placeholder="$t('msgSurvey2')"
                    type="text"
                    name="desc"
                    v-model="badSurveyDescription"
                    isRequired
                    :isValidate="$v.badSurveyDescription.$error"
                    :v="$v.badSurveyDescription"
                  />
                </b-col>
              </b-row>
            </template>
            <b-row class="my-3">
              <b-col>
                <b-button
                  :disabled="
                    (!form.Consent && form.TopicId !== 7) || isSaveForm || $store.state.isLoadingGlobal
                  "
                  @click="checkForm"
                  class="w-100 btn-main py-2"
                  >{{ $t("submit3") }}</b-button
                >
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlert
      ref="modalAlert"
      :text="modalMessage"
      v-on:hadleModalAlertClose="hadleModalAlertClose"
    />
    <ModalAlert
      ref="modalAlertInfo"
      :text="modalMessage"
      v-on:hadleModalAlertClose="$refs.modalAlertInfo.hide()"
      isShowCloseButton
    />
    <ModalAlertError
      ref="modalAlertError"
      :text="modalMessage"
      v-on:hadleModalAlertErrorClose="hadleModalAlertErrorClose"
    />
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
import InputSelect from "@/components/input/InputSelect";
import InputTextArea from "@/components/input/InputTextArea";
import UploadFilePanel from "@/components/complain/UploadFilePanel";
import { required, requiredIf, helpers, email } from "vuelidate/lib/validators";
import ModalLoading from "@/components/alert-modal/ModalLoading";
import ModalAlert from "@/components/alert-modal/ModalAlert";
import ModalAlertError from "@/components/alert-modal/ModalAlertError";
const alpha = helpers.regex("alpha", /^[\u0E00-\u0E7Fa-zA-Z ']*$/);

// import CoolLightBox from "vue-cool-lightbox";
// import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  components: {
    InputText,
    InputSelect,
    InputTextArea,
    UploadFilePanel,
    ModalLoading,
    ModalAlert,
    ModalAlertError
    // CoolLightBox
  },
  data() {
    return {
      index: null,
      lineProfile: {
        userId: "",
        displayName: "",
        pictureUrl: "",
        statusMessage: ""
      },
      modalMessage: "",
      Fullname: "",
      nameList: [],
      badSurveyDescription: "",
      isDelete: false,
      form: {
        UserGUID: "",
        TopicId: null,
        TrackingNo: "",
        Target: "",
        Description: "",
        Firstname: "",
        Lastname: "",
        Phone: "",
        Email: "",
        ContactChannel: "Telephone",
        Amount: 0,
        Consent: false,
        Attachment: [],
        SentimentScore: 0,
        adminReply: false,
        surveyScore: 0,
        isNonBussiness: false,
        formId: null
      },
      fileName: "",
      fileUpload: "",
      userData: {},
      complainOptions: [],
      fileBase64List: [],
      isSaveForm: false,
      urlLink: ""
    };
  },
  validations: {
    Fullname: {
      required,
      alpha
    },
    badSurveyDescription: {
      // required: requiredIf(function () {
      //   return this.form.TopicId == 7;
      // })
    },
    form: {
      UserGUID: {},
      TopicId: { required },
      TrackingNo: {
        // required: requiredIf(function () {
        //   return ![3, 4, 8, 6].includes(this.form.TopicId);
        // })
      },
      Target: {},
      Description: {
        required
        // required: requiredIf(function () {
        //   return [5].includes(this.form.TopicId);
        // })
      },
      Firstname: {
        // required
      },
      Lastname: {
        // required: requiredIf(function () {
        //   return !this.form.Firstname;
        // })
      },
      Phone: {
        required
        // required: requiredIf(function () {
        //   return this.form.ContactChannel == "Telephone";
        // })
      },
      Email: {
        // required: requiredIf(function () {
        //   return this.form.ContactChannel == "Email";
        // }),
        email
      },
      ContactChannel: {},
      Amount: {
        // required: requiredIf(function () {
        //   return this.form.TopicId == 1;
        // })
      },
      Consent: {},
      Attachment: {}
    }
  },
  beforeCreate: async function () {
    if (this.$liff.isInClient()) {
      this.$liff
        .init({ liffId: this.$liff_Main_ID })
        .then(() => {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  },
  created: async function () {
    //rebuild it
    await this.onGetBusinessHour();
    // this.sessionId = this.$route.query.sessionId;
    // if (this.sessionId) {
    //   await this.getUserData();
    // }
    if (this.$route.query.topicId) {
      this.form.TopicId = parseInt(this.$route.query.topicId);
      //   if (this.form.TopicId == 5) {
      //     this.form.Target = "Telephone";
      //   } else if (this.form.TopicId == 7) {
      if (this.form.TopicId == 7) {
        this.form.Target = "";
        if (!this.$route.query.formId) this.form.topicId = null;
        this.checkSurveyForm();
      }
      //   } else {
      //     this.form.Target = "";
      //   }
      // } else {
      //   this.form.TopicId = null;
    }

    // await this.checkSurveyForm();

    if (this.$route.query.sentimentScore) {
      this.form.SentimentScore = parseFloat(this.$route.query.sentimentScore);
    }
    if (this.$route.query.surveyScore) {
      this.form.surveyScore = parseFloat(this.$route.query.surveyScore);
    }

    await this.getTopicList();
    this.privacyLink();
  },

  methods: {
    checkSurveyForm: async function () {
      await this.$axios
        .get(this.$baseUrl + `/complain/${this.$route.query.formId}`)
        .then(response => {
          if (response.data.result !== 1) {
            // this.modalMessage = response.data.detail[0];
            // this.$refs.modalLoading.hide();
            // this.$refs.modalAlertError.show();
          } else {
            // this.modalMessage =
            //   "คุณได้ส่งข้อมูลให้เจ้าหน้าที่ติดต่อกลับแล้ว \n กรุณารอเจ้าหน้าที่ติดต่อกลับค่ะ";
            // this.$refs.modalLoading.hide();
            // this.$refs.modalAlertInfo.show();
          }

          // let isSurvey = response.data.detail.isSurveyForm;
          // if (!isSurvey) this.$router.push("/success/complained");
        });
    },
    privacyLink() {
      this.urlLink = `https://th.kerryexpress.com/${this.$cookies.get(
        "language"
      )}/privacy-notice`;
    },
    changeLanguage() {
      let getLanguage = this.$cookies.get("language");
      getLanguage = getLanguage == "th" ? "en" : "th";

      let finalUrl = `/complain/${getLanguage}${decodeURIComponent(
        window.location.search
      )}`;
      this.$router
        .replace(finalUrl)
        .then(() => {
          this.getTopicList();
          this.privacyLink();
        })
        .catch(() => {
          return;
        });
    },
    onUpdateAdminMode: async function () {
      await this.$axios
        .post(this.$baseUrl + "/user/updatemode", {
          adminMode: false,
          userGUID: this.form.UserGUID
        })
        .then(response => {
          if (response.data.result !== 1) {
            this.modalMessage = response.data.detail[0];
            this.$refs.modalLoading.hide();
            this.$refs.modalAlertError.show();
          }
        });
    },
    beforeOpenLightBox: async function (index) {
      if (this.form.Attachment[index] && !this.isDelete) {
        this.index = index;
      }
    },
    onGetBusinessHour: async function () {
      await this.$axios
        .get(this.$baseUrl + "/businessHour/1")
        .then(response => {
          if (response.data.result === 1) {
            this.form.isNonBussiness = !response.data.detail.isBusinessHour;
            if (!response.data.detail.isBusinessHour) {
              this.form.adminReply = true;
            } else {
              if (this.$route.query.adminReply == "true") {
                this.form.adminReply = true;
              }
            }
            this.sessionId = this.$route.query.sessionId;
            if (this.sessionId) {
              this.getUserData();
            }
          } else {
            if (this.$route.query.adminReply == "true") {
              this.form.adminReply = true;
            }
            this.sessionId = this.$route.query.sessionId;
            if (this.sessionId) {
              this.getUserData();
            }
          }
        })
        .catch(() => {
          if (this.$route.query.adminReply == "true") {
            this.form.adminReply = true;
          }
          this.sessionId = this.$route.query.sessionId;
          if (this.sessionId) {
            this.getUserData();
          }
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    hadleModalAlertErrorClose() {},
    hadleModalAlertClose() {
      if (this.$liff.isInClient()) {
        this.$liff.closeWindow();
      } else {
        this.$router.push("/success/complain");
      }
    },
    onSplitName: async function () {
      if (this.Fullname) {
        var arrName = this.Fullname.split(" ");
        arrName = arrName.map(el => el.trim());
        var usedName = [];
        arrName.forEach(element => {
          if (element !== "") {
            usedName.push(element);
          }
        });

        if (usedName.length > 1) {
          this.form.Firstname = usedName[0];
          this.form.Lastname = usedName[1];
        } else if (usedName.length == 1) {
          this.form.Firstname = usedName[0];
        }
      }
    },
    checkForm: async function () {
      if (this.form.TopicId !== 7) {
        await this.onSplitName();
        this.$v.$touch();
        if (this.$v.$error) {
          return;
        }
        // // if(this.form.isNonBussiness){
        //    if(this.form.Attachment.length > 0){
        //     for(let i = 0; i < this.form.Attachment.length; i++){
        //         this.submit({ ...this.form, Attachment: [], File: this.fileBase64List[i] || ""})
        //     }
        //    }else{
        //      this.submit({ ...this.form, Attachment: [], File: ""})
        //    }
        // // }else{
        this.submit(this.form);
        // }
      } else {
        this.$v.badSurveyDescription.$touch();
        if (this.$v.badSurveyDescription.$error) {
          return;
        }
        if (this.$route.query.formId)
          this.form.formId = this.$route.query.formId;
        this.form.Description = this.badSurveyDescription;
        this.submit(this.form);
      }
    },
    submit: async function (body) {
      this.isSaveForm = true;
      this.$refs.modalLoading.show();
      await this.$axios
        .post(`${this.$baseUrl}/complain/save`, body)
        .then(data => {
          if (data.data.result == 1) {
            this.isSaveForm = false;
            if (this.$liff.isInClient()) {
              this.modalMessage = this.$t("messageDoneSuccess");
              this.$refs.modalLoading.hide();
              this.$refs.modalAlert.show();
              setTimeout(() => {
                this.hadleModalAlertClose();
              }, 1000);
            } else {
              this.modalMessage = this.$t("messageDoneSuccess");
              this.$refs.modalAlert.show();
              setTimeout(() => {
                window.close();
              }, 1000);
              //this.$router.push("/success/complain");
            }
          } else {
            this.isSaveForm = false;
            this.modalMessage = data.data.detail[0];
            this.$refs.modalLoading.hide();
            this.$refs.modalAlertError.show();
          }
        });
    },
    onTopicChange(val) {
      this.form.TopicId = val;
      if (val == 5) {
        this.form.Target = "Telephone";
      } else {
        this.form.Target = "";
      }
      this.$v.$reset();
    },
    checkFileExtension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim();
    },
    deleteImage: async function (index) {
      this.isDelete = true;
      this.form.Attachment.splice(index, 1);
      this.fileBase64List.splice(index, 1);
      let arr = this.fileName.split(",");
      arr = arr.map(el => el.trim());
      await arr.splice(index, 1);
      this.fileName = "";
      arr.forEach(element => {
        this.fileName = this.fileName + element + (arr.length > 1 ? " " : "");
      });
      setTimeout(() => {
        this.isDelete = false;
      }, 500);
    },
    onImageChange(value) {
      let arrName = this.fileName.split(",");

      arrName = arrName.map(el => el.trim());

      if (arrName.length < 6) {
        this.fileName = this.fileName + value.fileName;
      }

      this.form.Attachment = value.filePath;
      this.fileBase64List = value.fileBase64;
    },
    getUserData: async function () {
      await this.$axios
        .get(this.$baseUrl + "/user/" + this.sessionId)
        .then(response => {
          if (response.data.result === 1) {
            if (response.data.detail.userGUID) {
              if (response.data.detail.isInitPrechat) {
                // this.submit(response.data.detail.complainForm);
                // return;
                this.modalMessage =
                  "คุณได้ส่งข้อมูลให้เจ้าหน้าที่ติดต่อกลับแล้ว \n กรุณารอเจ้าหน้าที่ติดต่อกลับค่ะ";
                this.$refs.modalLoading.hide();
                this.$refs.modalAlertInfo.show();
              }
              this.userData = response.data.detail;
              this.form.UserGUID = response.data.detail.userGUID;
              if (
                response.data.detail.firstname &&
                response.data.detail.firstname
              ) {
                // this.Fullname = `${response.data.detail.firstname} ${response.data.detail.firstname}`;
              }
              // this.form.Phone = response.data.detail.telephone;
              // if (this.$route.query.adminReply == "true") {
              //   this.form.adminReply = true;
              //   this.onUpdateAdminMode();
              // }
              // if (this.form.adminReply == true) {
              //   this.onUpdateAdminMode();
              // }
            } else {
              this.$router.push("/session-expired");
            }
          }
        });
    },
    getTopicList: async function () {
      await this.$axios
        .get(this.$baseUrl + "/complain/topic")
        .then(response => {
          if (response.data.result === 1) {
            let list = [{ id: null, name: `${this.$t("pleaseSelectTopic")}` }];
            let datalist = response.data.detail.filter(x=> x.id != 7);
            this.complainOptions = list.concat(datalist);
          }
        });
    },
    handleChangeComplain() {}
  }
};
</script>

<style lang="scss" scoped>
.icon-play {
  position: absolute;
  top: 30%;
  left: 27%;
  z-index: 99;
  color: white;
  width: 40px;
  height: 40px;
}

::v-deep .custom-radio .custom-control-label::before {
  border-radius: 0.25rem;
}
::v-deep
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.text-error {
  color: #dc3545 !important;
  font-weight: bold;
  font-size: 16px;
}

.logo-img {
  width: 100px;
  height: auto;
  text-align: center;
}
.complain-header {
  height: 50px;
}
.complain-container {
  height: calc(100vh - 60.5px);
}
.complain-card {
  width: 100%;
}
.complain-position {
  position: relative;
}
.icon-pdf-delete {
  position: absolute;

  top: 4%;
}
::v-deep .card {
  border-color: white;
  border-radius: 14px;
}

.icon-pdf {
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.panel-bg-file-image {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /* padding-bottom: 100%; */
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.detail-format {
  color: #9b9b9b;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 0px;
}
.text-label {
  color: #58595b;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}

.btn-language {
  background: white !important;
  color: #f37021 !important;
  border: none;
}

.position-btn-language {
  position: absolute;
  right: 15px;
  top: 10px;
}

::v-deep .col-form-label {
  color: #58595b;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
::v-deep .input-custom > textarea {
  height: calc(100vh - 250px);
}
::v-deep .cool-lightbox-video {
  width: auto !important;
  height: auto !important;
}
::v-deep .cool-lightbox-button {
  background-color: transparent !important;
}
@media only screen and (max-width: 400px) {
  .custom-mt-2 {
    margin-top: 10px;
  }
}
@media only screen and (min-width: 767.98px) {
  .complain-card {
    max-width: 800px;
  }
  .detail-format {
    font-size: 11px;
  }
  .text-error,
  .text-label {
    font-size: 15px;
  }
  ::v-deep .col-form-label {
    font-size: 15px;
  }
}
</style>
